import React, { useState } from "react";
import { Card } from "react-bootstrap";
import BlogAvatar from "../BlogAvatar";
import "./blogCard.scss";
import { routeUrl } from "../../constants";
import BlogDeleteModal from "../BlogDeleteModal";

const trimString = str => {
  var trimmedString = str.replace(/(<([^>]+)>)/gi, "").substr(0, 170);

  //re-trim if we are in the middle of a word
  return trimmedString.substr(
    0,
    Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
  );
};

const BlogCard = ({
  blog,
  column,
  onClick,
  pathname,
  onClickEdit,
  onDeletePost
}) => {
  const [isDeleteModalOpen, showDeleteModal] = useState(false);
  const [selectedPostId, selectPost] = useState(null);

  const toggleModal = () => showDeleteModal(!isDeleteModalOpen);

  const onDeleteClick = postId => {
    selectPost(postId);
    toggleModal();
  };

  return (
    <div
      className={column}
      onClick={event => {
        if (!isDeleteModalOpen) {
          onClick(blog.id);
        }
      }}
    >
      <Card className="md-blog-card mt-3 mt-md-5">
        <div
          className="md-blog-card-img d-flex align-items-end"
          style={{ backgroundImage: `url(${blog.featureImage})` }}
        >
          {pathname === routeUrl.myBlog && (
            <div className="md-blog-card-action">
              <button
                className="hd-btn-circle hd-icon-close hd-btn-danger"
                onClick={event => {
                  onDeleteClick(blog.id);
                  event.stopPropagation();
                }}
              />
              <button
                className="hd-btn-circle hd-icon-pencil hd-btn-dark"
                onClick={event => {
                  onClickEdit(blog.id);
                  event.stopPropagation();
                }}
              />
            </div>
          )}
        </div>
        <Card.Body className="d-flex flex-column justify-content-between">
          <div>
            <Card.Title className="text-uppercase text-truncate mb-0">
              {blog.title}
            </Card.Title>
            <Card.Text>{trimString(blog.description)}</Card.Text>
          </div>
          <BlogAvatar
            authorImage={blog.authorImage}
            authorName={blog.authorName}
            timestamp={blog.updatedAt}
            description={blog.description}
          />
        </Card.Body>
      </Card>
      {isDeleteModalOpen && (
        <BlogDeleteModal
          postId={selectedPostId}
          toggleModal={toggleModal}
          onDeletePost={onDeletePost}
        />
      )}
    </div>
  );
};

export default BlogCard;
