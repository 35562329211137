import React from "react";

const EnergyVision = () => (
  <div>
    <div className="modal-header">
      <h1 className="modal-title">
        <span className="primary-text-color">hD</span> Corrosion Vision -{" "}
        <span className="grey-text-color">CV</span>
      </h1>
    </div>
    <div className="modal-body">
      <div className="hd-modal-detail-bullet text-left mb-5">
        <ul className="rect-green-list-style p-0 m-0">
          <li className="mb-2">
            Take the guesswork out of corrosion inspection with AI-assisted corrosion risk manager with features curated and algorithm tuned by corrosion Subject Master Expert
          </li>
          <br/>
          <br/>
          <li className="mb-2">Reduce QA and QC cost by with objective and consistent result</li>
          <br/>
          <br/>
          <li className="mb-2">Surpass expectations with the fastest and cost effective solution driven by state of the art computer vision and deep learning technology</li>
          <br/>
          <br/>
          <li className="mb-2">
            Exceed compliance by archiving, backing up and even retrieving corrosion data anytime
          </li>
          <br/>
          <br/>
          <li className="mb-2">Deployed on batch and realtime operation</li>
        </ul>
      </div>
      <br/>
      <br/>
      <div className="hd-modal-detail-bullet-sm text-left">
        <label className="m-0">Sources:</label>
        <ul className="no-list-style p-0 m-0">
          <li className="md-break-word">
            <a href="https://www.onepetro.org/conference-paper/SPE-190036-MS">
              https://www.onepetro.org/conference-paper/SPE-190036-MS
            </a>
          </li>
          <li className="md-break-word">
            <a href="https://github.com/Muda413/Advanced_Lane_Lines">
              https://github.com/Muda413/Advanced_Lane_Lines
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default EnergyVision;
