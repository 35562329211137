import React from "react";
import { connect } from "react-redux";
import { replace } from "connected-react-router";
import HeaderModal from "../components/HeaderModal";
import { headerModal as headerConstants } from "../constants";

const HeaderModalContainer = props => {
  const { queryString, toggleModal, fromAuthModal, isUserSignedIn } = props;
  const params = new URLSearchParams(queryString);
  const modalView = params.get("modalView");

  if (
    isUserSignedIn ||
    ((modalView === headerConstants.authorize ||
      modalView === headerConstants.verify) &&
      !fromAuthModal)
  ) {
    return null;
  }

  if (
    modalView !== headerConstants.signup &&
    modalView !== headerConstants.signin &&
    modalView !== headerConstants.verify &&
    modalView !== headerConstants.authorize
  ) {
    return null;
  }
  return <HeaderModal modalView={modalView} toggleModal={toggleModal} />;
};

function mapStateToProps({ router, auth }) {
  return {
    queryString: router.location.search,
    isUserSignedIn: auth.signedInUser.isUserSignedIn,
    fromAuthModal: router.location.state && router.location.state.fromAuthModal
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleModal: () => dispatch(replace({ search: "" }))
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderModalContainer);
