import React from "react";
import "./productSelfDriving.scss";

const SelfDriving = () => (
  <div className="md-selfDriving-modal">
    <div className="modal-header">
      <h1 className="modal-title">Autonomous Drilling - Oil & Gas + Geothermal Energy</h1>
    </div>
    <div className="modal-body">
      <div className="row mb-md-5">
        <div className="col-md-4 d-flex flex-column align-items-center">
          <label>
            <span className="primary-text-color">hD</span> Drilling Intelligence
          </label>
          <div className="hd-circles rounded-circle d-flex justify-content-center align-items-center hd-plus-after">
            Domain Expertise<br/>
            +<br/>
            Filter Algorithms<br/>
            +<br/>
            Search Algorithms<br/>
            +<br/>
            Deep Learning<br/>
            +<br/>
            Reinforcement Learning
          </div>
        </div>
        <div className="col-md-4 d-flex flex-column align-items-center">
          <label>
            <span className="primary-text-color">hD</span> Energy Infrastructure
          </label>
          <div className="hd-circles rounded-circle d-flex justify-content-center align-items-center hd-equal-after">
            Serverless<br/>
            +<br/>
            Microservices<br/>
            +<br/>
            Cloud Computing<br/>
            +<br/>
            Edge Computing<br/>
            +<br/>
            GPU / FPGA
          </div>
        </div>
        <div className="col-md-4 d-flex flex-column align-items-center">
          <label>
            <span className="primary-text-color">hD</span> ToolPusher
          </label>
          <div className="hd-circles rounded-circle gradient-circle d-flex justify-content-center align-items-center">
            Accurate drilling measurement data <br/>
            +<br/>
            Real time optimized well trajectory planning<br/>
            +<br/>
            Geosteer to target with higher precision<br/>
            +<br/>
            Side tracks reduction<br/>
            +<br/>
            More access to productive interval
          </div>
        </div>
      </div>
      <div className="hd-modal-detail text-left">
        <h3 className="mb-3">hD ToolPusher</h3>
        <div className="hd-modal-detail-bullet mb-3">
          <ul className="rect-green-list-style p-0 m-0">
            <li>Smart edge device fitted within BHA</li>
            <br/>
            <li>Retrofit existing BHA electronic equipments</li>
            <br/>
            <li>Deploy smart edge agent daemon to BHA electronics</li>
          </ul>
        </div>
        <br/>
        <h3 className="mb-3">Service - <strong>No Hardware</strong></h3>
        <div className="hd-modal-detail-bullet">
          <ul className="rect-green-list-style p-0 m-0">
            <li>Improved accuracy of measurements while drilling Real time trajectory generation
                for exploration and development wells - with or without geologic markers</li>
              <br/>
            <li>More precise bit location estimation and direction guidance</li>
            <br/>
            <li>Reduce dogleg severity</li>
            <br/>
            <li>Deploy smart edge agent daemon to BHA electronics</li>
          </ul>
        </div>
      </div>
      <br/>
      <br/>
      <div className="hd-modal-detail-bullet-sm text-left">
        <label className="m-0">Sources:</label>
        <ul className="no-list-style p-0 m-0">
          <li className="md-break-word">
            <a href="https://www.slideshare.net/CJEjimuda/self-driving-directional-drilling-on-the-edge">
              https://www.slideshare.net/CJEjimuda/self-driving-directional-drilling-on-the-edge
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default SelfDriving;
