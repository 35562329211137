import React from "react";
import { connect } from "react-redux";
import SigninError from "../components/SigninError";
import { asyncResendConfirmCode } from "../actions/auth";

const SigninErrorContainer = ({ signinData, asyncResendConfirmCode }) => {
  if (!signinData.code) {
    return null;
  }
  const signinError = signinData;

  return (
    <SigninError
      signinError={signinError}
      asyncResendConfirmCode={asyncResendConfirmCode}
    />
  );
};

function mapStateToProps({ auth }) {
  return {
    signinData: auth.signin
  };
}

function mapDispatchToProps(dispatch) {
  return {
    asyncResendConfirmCode: () => dispatch(asyncResendConfirmCode())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SigninErrorContainer);
