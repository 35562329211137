import React from "react";

const Energy = () => (
  <div>
    <div className="modal-header">
      <h1 className="modal-title">Power + Energy</h1>
    </div>
    <div className="modal-body">
      <div className="text-left mb-5">
        <ul className="rect-green-list-style p-0 m-0">
          <li className="mb-2">
            Simplify end to end Power and Energy Assets Portfolio Management in complex
            and dynamic market condition
          </li>
          <br/>
          <br/>
          <li className="mb-2">
            Thorough market and economics evaluation leveraging custom AI
            algorithm, Big data, IIoT and Robotics
          </li>
          <br/>
          <br/>
          <li className="mb-2">
            Factor in direct and indirect business drivers into model
            supplemented by rich domain expertise
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default Energy;
