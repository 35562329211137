import React from "react";

const Industrial = () => (
  <div>
    <div className="modal-header">
      <h1 className="modal-title">Industrial | Commercial</h1>
    </div>
    <div className="modal-body">
      <div className="text-left mb-5">
        <ul className="rect-green-list-style p-0 m-0">
          <li className="mb-2">
            Take advantage of value derived from continuos deployment of
            algorithm on the edge of IIoT devices
          </li>
          <br/>
          <br/>
          <li className="mb-2">
            Secured embedded or gateway devices and data encrypted during
            transmission to local area connection, gateway and cloud
          </li>
          <br/>
          <br/>
          <li className="mb-2">
            Continuous model tuning, monitoring and over the top security
            updates
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default Industrial;
