import React, { useEffect } from "react";
import Home from "../Home";
import Products from "../Products";
import Solutions from "../Solutions";
import Company from "../Company";
import Bullets from "../../container/Bullets";
import { createObserver } from "../../services/intersectionObserver";

const Landing = () => {
  useEffect(() => {
    const target1 = document.querySelector("#home");
    const target2 = document.querySelector("#products");
    const target3 = document.querySelector("#solutions");
    const target4 = document.querySelector("#contact");

    const observer = createObserver();
    observer.observe(target1);
    observer.observe(target2);
    observer.observe(target3);
    observer.observe(target4);

    return function cleanup() {
      observer.unobserve(target1);
      observer.unobserve(target2);
      observer.unobserve(target3);
      observer.unobserve(target4);
    };
  }, []);

  return [
    <Home key="home" />,
    <Products key="products" />,
    <Solutions key="solutions" />,
    <Company key="company" />,
    <Bullets key="bullets" />
  ];
};

export default Landing;
