import React from "react";
import { connect } from "react-redux";
import VerifyCodeError from "../components/VerifyCodeError";

const VerifyCodeErrorContainer = ({ verifyCodeError }) => {
  if (!verifyCodeError.code) {
    return null;
  }

  return <VerifyCodeError codeError={verifyCodeError} />;
};

function mapStateToProps({ auth }) {
  return {
    verifyCodeError: auth.codeError
  };
}

export default connect(mapStateToProps)(VerifyCodeErrorContainer);
