import React from "react";
import { Switch, Route } from "react-router-dom";

import PrivateRoute from "./container/PrivateRoute";
import { routeUrl } from "./constants";

import Landing from "./components/Landing";
import Header from "./container/Header";
import Blog from "./container/Blog";
import TollIts from "./container/TollIts";
import EnergyMarkets from "./container/ProjectFinanceEnergyTrading";
import FinTech from "./container/FinTech";
import EnergyEfficiency from "./container/EnergyEfficiency";
import GasMarkets from "./container/GasMarkets";
import BlogDetails from "./container/BlogDetails";
import EditProfile from "./components/EditProfile";
import CreateBlog from "./container/CreateBlog";
import MyBlog from "./container/MyBlog";
import EditBlog from "./container/EditBlog";
import SlackChat from "./components/SlackChat";

const Routes = props => [
  <SlackChat key="chat" />,
  <Header key="header" />,
  <Switch key="appRoutes">
    <Route exact path={routeUrl.blog} component={Blog} />
    <Route exact path={routeUrl.tollIts} component={TollIts} />
    <Route exact path={routeUrl.energyEfficiency} component={EnergyEfficiency} />
    <Route exact path={routeUrl.finTech} component={FinTech} />
    <Route exact path={routeUrl.energyMarkets} component={EnergyMarkets} />
    <Route exact path={routeUrl.gasMarkets} component={GasMarkets} />
    <PrivateRoute exact path={routeUrl.myBlog} component={MyBlog} />
    <PrivateRoute exact path={routeUrl.createBlog} component={CreateBlog} />
    <PrivateRoute exact path={routeUrl.editBlog} component={EditBlog} />
    <Route exact path={routeUrl.blogDetail} component={BlogDetails} />
    <PrivateRoute exact path={routeUrl.editProfile} component={EditProfile} />
    <Route path={routeUrl.base} component={Landing} />
  </Switch>
];
export default Routes;
