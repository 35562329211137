import React from "react";
import Modal from "react-bootstrap/Modal";
import SignupForm from "../../container/SignupForm";
import UserConfirmForm from "../../container/UserConfirmForm";
import UserAuthorizeForm from "../../container/UserAuthorizeForm";
import SigninForm from "../../container/SigninForm";
import { headerModal as headerConstants } from "../../constants";
import "./headerModal.scss";

const HeaderModal = ({ toggleModal, modalView }) => {
  return (
    <Modal
      show
      size="md modal-auth"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={toggleModal}
    >
      <button
        type="button"
        className="modal-close rounded-circle position-absolute hd-icon-close"
        onClick={toggleModal}
      />
      {
        {
          [headerConstants.signup]: <SignupForm />,
          [headerConstants.signin]: <SigninForm />,
          [headerConstants.verify]: <UserConfirmForm />,
          [headerConstants.authorize]: <UserAuthorizeForm />
        }[modalView]
      }
    </Modal>
  );
};

export default HeaderModal;
