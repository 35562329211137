import React from "react";


function Bizqualify (props){

return(
  <div style={{ display: "flex",justifyContent: "center"}}>
        <iframe src={props.pdfurl} width="960" height="800" allow="autoplay">
        </iframe>
  </div>
)

};

function fintechBizQualify(){
  return(
      <Bizqualify pdfurl="https://drive.google.com/file/d/1gOxwx9CG-ox3TafzYgA1fwW9sZdyt4AU/preview" />
  );
}

export default fintechBizQualify;

