import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TextField from "../TextField";
import PrimaryButton from "../PrimaryButton";
import ContentEditor from "../Editor";
import TagSelector from "../TagSelect";
import FeatureImage from "../FeatureImage";

const EditProfileSchema = Yup.object().shape({
  title: Yup.string().required("Title is Required"),
  description: Yup.string().required("Description is required"),
  featureImage: Yup.string().required("Feature Image is required")
});

const initialValues = {
  title: "",
  description: "",
  tags: [],
  featureImage: ""
};

const BlogForm = ({ handleSubmit, formValues }) => (
  <div>
    <Formik
      initialValues={formValues || initialValues}
      validationSchema={EditProfileSchema}
      onSubmit={values => {
        const post = {
          title: values.title,
          description: values.description,
          publishedAt: parseInt(Date.now() / 1000),
          featureImage: values.featureImage,
          tags: values.tags.map(val => val.value)
        };
        handleSubmit(post);
      }}
    >
      {({ setFieldValue, values, errors, touched, setFieldTouched }) => (
        <Form autoComplete="off">
          <div className="row">
            <div className="col-md-4">
              <TextField
                className="hd-form-field hd-light-field"
                name="title"
                type="text"
                label="Title"
                placeholder="Post title"
              />
              <FeatureImage
                value={values.featureImage}
                onImageUpload={value => setFieldValue("featureImage", value)}
                touched={touched["featureImage"]}
                error={errors["featureImage"]}
              />
              <TagSelector
                label="Tags"
                name="tags"
                value={values.tags}
                onChange={value => setFieldValue("tags", value)}
              />
            </div>
            <div className="col-md-8">
              <ContentEditor
                name="description"
                label="Description"
                value={values.description}
                touched={touched["description"]}
                error={errors["description"]}
                onChange={value => setFieldValue("description", value)}
                onBlur={() => setFieldTouched("description", true)}
              />
            </div>
            <div className="col-12 text-center mt-3">
              <PrimaryButton className="hb-btn-small" type="submit">
                Publish Post
              </PrimaryButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  </div>
);

export default BlogForm;
