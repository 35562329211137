import React from "react";
import { CardGroup } from "react-bootstrap";

import BlogCard from "../../container/BlogCard";

const Blog = ({ blogList, refetchBlogList }) => {
  return (
    <section className="md-fluid-section md-page-section md-default-section md-default-section-right-bg">
      <div className="container  px-md-0 flex-column">
        <div className="md-page-title text-center mt-4 mb-4">
          <h1>hybriData blog</h1>
        </div>
        <div className="md-page-body">
          <CardGroup>
            <div className="row">
                Check back soon. . .
            </div>
          </CardGroup>
        </div>
      </div>
    </section>
  );
};

export default Blog;
