import React from "react";
import { Image } from "react-bootstrap";
import "./blogAvatar.scss";
import { formatDate, calculateReadingTime } from "../../services/helper";

const BlogAvatar = ({ authorImage, authorName, timestamp, description }) => {
  return (
    <div className="md-blog-card-avatar d-flex align-content-center">
      <Image height={40} width={40} src={authorImage} roundedCircle />
      <div className="ml-3 d-flex flex-column justify-content-center">
        <label className="m-0 font-weight-bold">{authorName}</label>
        <p className="m-0">
          {formatDate(timestamp)}, {calculateReadingTime(description)} mins read
        </p>
      </div>
    </div>
  );
};

export default BlogAvatar;
