import React from "react";
import { connect } from "react-redux";
import SigninForm from "../components/SigninForm";

import { asyncSignin } from "../actions/auth";

const SigninFormContainer = ({ asyncSignin }) => {
  return <SigninForm asyncSignin={asyncSignin} />;
};

function mapDispatchToProps(dispatch) {
  return {
    asyncSignin: signinObj => dispatch(asyncSignin(signinObj))
  };
}

export default connect(
  null,
  mapDispatchToProps
)(SigninFormContainer);
