import {
  SEND_SIGNUP_REQUEST,
  SEND_SIGNIN_REQUEST,
  VERIFY_CODE_ERROR
} from "../actions/auth";
import { SET_SIGNEDIN_USER } from "../actions/auth";

export const initialState = {
  signup: { user: {} },
  signin: {},
  signedInUser: { isUserSignedIn: true },
  codeError: {}
};

export const auth = (state = initialState, action) => {
  switch (action.type) {
    case SEND_SIGNUP_REQUEST:
      return {
        ...state,
        signup: action.payload
      };
    case SEND_SIGNIN_REQUEST:
      return {
        ...state,
        signin: action.payload
      };
    case SET_SIGNEDIN_USER:
      return {
        ...state,
        signedInUser: action.payload
      };
    case VERIFY_CODE_ERROR:
      return {
        ...state,
        codeError: action.payload
      };
    default:
      return state;
  }
};
