import React from "react";
import { connect } from "react-redux";
import ProfileDropdown from "../components/ProfileDropdown";
import { asyncSignout } from "../actions/auth";

const ProfileDropdownContainer = props => {
  const { username, picture, asyncSignout } = props;
  return <ProfileDropdown username={username} picture={picture} asyncSignout={asyncSignout} />;
};

function mapStateToProps({ auth }) {
  return {
    username: auth.signedInUser.username,
    picture: auth.signedInUser.attributes.picture,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    asyncSignout: () => dispatch(asyncSignout())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileDropdownContainer);
