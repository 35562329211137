import React from "react";

const SmartAgent = () => (
  <div>
    <div className="modal-header">
      <h1 className="modal-title">
        <span className="primary-text-color">hD</span> TransforMAX
      </h1>
    </div>
    <div className="modal-body">
      <div className="hd-modal-detail-bullet text-left mb-5">
        <ul className="rect-green-list-style p-0 m-0">
          <li className="mb-2">
            Reduce maintenance cost by 50 % by proactively alerting all responsible stakeholders of their transformer fleet health conditions
          </li>
          <br/>
          <br/>
          <li className="mb-2">
            Prevent electrical faults, transformer explosion or fire
          </li>
          <br/>
          <br/>
          <li className="mb-2">
            Improve tranformer fleet efficiency, save energy by reducing transformer power losses
          </li>
          <br/>
          <br/>
          <li className="mb-2">
            Enhance operational situational awareness of power system
          </li>
        </ul>
      </div>
      <br/>
      <br/>
      <div className="hd-modal-detail-bullet-sm text-left">
        <label className="m-0">Sources:</label>
        <ul className="no-list-style p-0 m-0">
          <li className="md-break-word">
            <a href="https://www.slideshare.net/CJEjimuda/internet-of-energy-can-python-prevent-california-wildfires">
              https://www.slideshare.net/CJEjimuda/internet-of-energy-can-python-prevent-california-wildfires
            </a>
          </li>
          <li className="md-break-word">
            <a href="https://www.slideshare.net/CJEjimuda/optimizing-pv-energy-yield-with-elasticsearch-and-graphql">
              https://www.slideshare.net/CJEjimuda/optimizing-pv-energy-yield-with-elasticsearch-and-graphql
            </a>
          </li>
          <li className="md-break-word">
            <a href="https://github.com/cjhD/aapg_hD_IIoT_shortcourse">
              https://github.com/cjhD/aapg_hD_IIoT_shortcourse
            </a>
          </li>
          <li className="md-break-word">
            <a href="https://www.slideshare.net/CJEjimuda/hybridata-iiot-workshop-for-aapg-short-course">
              https://www.slideshare.net/CJEjimuda/hybridata-iiot-workshop-for-aapg-short-course
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default SmartAgent;
