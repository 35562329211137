import React, { Component } from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import AWSAppSyncClient from "aws-appsync";
import { ApolloProvider } from "react-apollo";
import { Rehydrated } from "aws-appsync-react";

import Routes from "./routes";
import store, { history } from "./store";
import createHistoryHashObserver from "./services/createHistoryHashObserver";
import { asyncSignedInUser } from "./actions/auth";
import amplifyPublicConfig from "./aws-exports-public";

const publicApiClient = new AWSAppSyncClient({
  url: amplifyPublicConfig.public_graphql_api_url,
  region: amplifyPublicConfig.public_graphql_api_region,
  auth: {
    type: "API_KEY",
    apiKey: amplifyPublicConfig.public_graphql_api_api_key
  }
});

class App extends Component {
  componentWillMount() {
    createHistoryHashObserver(history);
    store.dispatch(asyncSignedInUser());
  }
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ApolloProvider client={publicApiClient}>
            <Rehydrated>
              <Routes />
            </Rehydrated>
          </ApolloProvider>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
