import React from "react";
import { connect } from "react-redux";
import SignupForm from "../components/SignupForm";

import { asyncSignup } from "../actions/auth";

const SignupFormContainer = props => {
  const { asyncSignup } = props;
  return <SignupForm asyncSignup={asyncSignup} />;
};

function mapStateToProps({ app, router }) {
  return {
    activeUrlHash: app.activeUrlHash,
    currentPath: router.location.pathname
  };
}

function mapDispatchToProps(dispatch) {
  return {
    asyncSignup: signupObj => dispatch(asyncSignup(signupObj))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignupFormContainer);
