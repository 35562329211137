import React from "react";
import Modal from "react-bootstrap/Modal";
import SelfDriving from "../ProductSelfDriving";
import EnergyVision from "../ProductEnergyVision";
import SmartAgent from "../ProductSmartAgent";
import TechnicalAdvisor from "../ProductTechnicalAdvisor";
import { products } from "../../constants";
import "./productModal.scss";

const ProductModal = ({ selectedProduct, toggleModal }) => {
  return (
    <Modal
      show
      size="fluid"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={toggleModal}
    >
      <button
        type="button"
        className="modal-close rounded-circle position-absolute hd-icon-close"
        onClick={toggleModal}
      />
      {
        {
          [products.selfDriving]: <SelfDriving />,
          [products.energyVision]: <EnergyVision />,
          [products.smartAgent]: <SmartAgent />,
          [products.technicalAdvisor]: <TechnicalAdvisor />
        }[selectedProduct]
      }
    </Modal>
  );
};

export default ProductModal;
