/* eslint no-nested-ternary: 0 */
import React from "react";
import { Route, Redirect } from "react-router-dom";

import { routeUrl } from "../../constants";

const PrivateRoute = props => {
  const { component: TargetComponent, isUserSignedIn, ...restProps } = props;
  return (
    <Route
      {...restProps}
      render={targetComponentProps =>
        // Redirect user to login if token not available
        isUserSignedIn ? (
          <TargetComponent {...targetComponentProps} />
        ) : (
          <Redirect to={routeUrl.home} />
        )
      }
    />
  );
};

export default PrivateRoute;
