import React from "react";

const PrimaryButton = ({ type, children, className }) => (
  <button
    type={type}
    className={'hd-btn hd-btn-primary hd-btn-primary-shadow ' + className}
  >
    {children} 
  </button>
);
export default PrimaryButton;
