import * as React from "react";
import { Field } from "formik";
const ContactUsInput = ({ name, placeholder, type, iconClassName }) => {
  return (
    <Field
      name={name}
      render={props => {
        const {
          form: { errors, touched },
          field
        } = props;
        const showError = errors[name] && touched[name];
        const errorClass = showError ? "visible" : "invisible";
        return (
          <div className="hd-form-field hd-field-icon hd-dark-field">
            <i className={iconClassName} />
            <input
              {...field}
              name={name}
              type={type || "text"}
              className="form-control"
              placeholder={placeholder}
            />
            {name === "email" && (
              <div className={`invalid-feedback d-inline-block ${errorClass}`}>
                {errors[name]}
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

export default ContactUsInput;
