import React from "react";
import { connect } from "react-redux";
import UserAuthorizeForm from "../components/UserAuthorizeForm";

import { asyncUserAuthorize } from "../actions/auth";

const UserAuthorizeFormContainer = props => {
  const { asyncUserAuthorize, signinData } = props;
  if (signinData.challengeName !== "SMS_MFA") {
    return null;
  }
  return <UserAuthorizeForm asyncUserAuthorize={asyncUserAuthorize} />;
};

function mapStateToProps({ auth }) {
  return {
    signinData: auth.signin
  };
}

function mapDispatchToProps(dispatch) {
  return {
    asyncUserAuthorize: code => dispatch(asyncUserAuthorize(code))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAuthorizeFormContainer);
