import React from "react";
import { Link } from "react-router-dom";
import persons from "../../assets/images/section-home-persons-vector-bg.png";
import "./home.scss";
import { routeUrl } from "../../constants/";

const Home = () => {
  return (
    <section id="home" className="md-fluid-section md-text-center-mob">
      <div className="container px-md-0 position-relative zi-1">
        <div className="align-items-center row vh-100 flex-column flex-sm-row-reverse justify-content-center">
          <div className="col-sm-5">
            <img
              src={persons}
              alt="Persons"
              className="position-relative hd-person-img w-100 zi-1"
            />
          </div>
          <div className="col-md-7">
            <h1>
              We mine more value from
              <br />
              power and energy data.
            </h1>
            <small>
              Leveraging our power and energy industry rich domain expertise, big data, IIoT,<br />
              robotics, computer vision and proprietary  ML / AI algorithms
            </small>
            <div className="mt-4">
              <Link to={routeUrl.contact}>
                <button
                  type="button"
                  className="hd-btn hd-btn-large hd-btn-primary hd-btn-primary-shadow"
                >
                  Contact us
                </button>
              </Link>
              <Link to={routeUrl.solutions}>
                <button type="button" className="hd-btn hd-btn-large ml-3">
                  Learn more
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
