import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import ContactUsInput from "../ContactUsInput";
import TextArea from "../TextArea";

const ContactUsSchema = Yup.object().shape({
  email: Yup.string().required("Email Required")
});

const ContactUsForm = ({ submitForm }) => (
  <Formik
    initialValues={{
      email: "",
      firstName: "",
      lastName: "",
      comment: ""
    }}
    validationSchema={ContactUsSchema}
    onSubmit={(values, {resetForm}) => {
      submitForm({ variables: { form: values } })
      resetForm();
    }}
  >
    {({ handleChange }) => (
      <Form className="my-3" autoComplete="off">
        <div className="row">
          <div className="col-md-6 mb-xs-4">
            <div className="mb-4">
              <ContactUsInput
                name="firstName"
                iconClassName="hd-icon-user"
                placeholder="First name"
              />
            </div>
            <div className="mb-4">
              <ContactUsInput
                name="lastName"
                iconClassName="hd-icon-user"
                placeholder="Last name"
              />
            </div>
            <div>
              <ContactUsInput
                name="email"
                type="email"
                iconClassName="hd-icon-mail"
                placeholder="E-mail address"
              />
            </div>
          </div>
          <div className="col-md-6 pl-md-0">
            <TextArea
              name="comment"
              type="textarea"
              as="textarea"
              className="hd-form-field hd-dark-field hd-no-label h-100"
              placeholder="Your message .."
            />
          </div>
          <div className="col-12 mt-4 mt-xs-0">
            <button
              name="submit-button"
              type="submit"
              className="hd-btn hd-btn-primary hd-btn-primary-shadow hd-btn-small"
            >
              Send
            </button>
          </div>
        </div>
      </Form>
    )}
  </Formik>
);

export default ContactUsForm;
