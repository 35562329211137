import gql from "graphql-tag";

export const getPosts = gql`
  query GetPost($author: String) {
    getPosts(author: $author) {
      id
      userId
      title
      description
      featureImage
      createdAt
      updatedAt
      publishedAt
      tags
      viewCount
      authorName
      authorImage
    }
  }
`;

export const getPost = gql`
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      userId
      title
      description
      featureImage
      createdAt
      updatedAt
      publishedAt
      tags
      viewCount
      authorName
      authorImage
    }
  }
`;

export const addPost = gql`
  mutation CreatePost($post: PostCreate!) {
    createPost(post: $post) {
      id
    }
  }
`;

export const updatePost = `mutation CreatePost($post: PostUpdate!) {
  updatePost(post: $post) {
      id
  }
}`;

export const deletePost = `mutation DeletePost($id: ID!) {
  deletePost(id: $id) {
      id
  }
}`;
