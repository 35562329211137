import React from "react";
import "./copyrightLabel.scss";

const CopyrightLabel = ({ className }) => (
  <div className={'hd-copyright mt-5 ' + className}>
    © 2023 Hybrid Data LLC, Los Angeles, CA. All Rights Reserved.
  </div>
);

export default CopyrightLabel;
