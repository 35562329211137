import { Auth } from "aws-amplify";
import { push } from "connected-react-router";
import { routeUrl } from "../constants/";
import { EventTypes } from 'redux-segment';

export const SEND_SIGNUP_REQUEST = "SEND_SIGNUP_REQUEST";
export const SET_SIGNEDIN_USER = "SET_SIGNEDIN_USER";
export const SEND_SIGNIN_REQUEST = "SEND_SIGNIN_REQUEST";
export const VERIFY_CODE_ERROR = "VERIFY_CODE_ERROR";

export const asyncSignup = ({
  name,
  email,
  password,
  phoneNumber
}) => async dispatch => {
  try {
    const data = await Auth.signUp({
      username: email,
      password: password,
      attributes: {
        email: email,
        phone_number: phoneNumber,
        name: name
      }
    });
    const signupData = {
      name,
      email,
      phone: phoneNumber,
      username: data.user.username,
      isUserConfirmed: data.isUserConfirmed
    };
    console.log('signupData',signupData);
    dispatch(setSignupData(signupData));
    dispatch(push(routeUrl.verify, { fromAuthModal: true }));
  } catch (error) {
    dispatch(setSignupData(error));
  }
};

export const asyncUserConfirm = code => async (dispatch, getState) => {
  const {
    auth: {
      signup: { username }
    }
  } = getState();
  try {
    const data = await Auth.confirmSignUp(username, code, {
      forceAliasCreation: true
    });

    if (data === "SUCCESS") {
      dispatch(push({ search: routeUrl.signin }));
    }
  } catch (error) {
    dispatch(setVerifyCodeError(error));
  }
};

export const asyncUserAuthorize = code => async (dispatch, getState) => {
  const {
    auth: { signin }
  } = getState();
  try {
    await Auth.confirmSignIn(signin, code, "SMS_MFA");
    dispatch(push({ search: "" }));
    dispatch(asyncSignedInUser());
  } catch (error) {
    dispatch(setVerifyCodeError(error));
  }
};

export const asyncSignin = ({ email, password }) => async dispatch => {
  try {
    const user = await Auth.signIn({
      username: email,
      password: password
    });
    if (user.challengeName) {
      dispatch(setSigninData(user)); //{code, message, name}
      dispatch(push(routeUrl.authorize, { fromAuthModal: true }));
    } else {
      dispatch(push({ search: "" }));
      dispatch(asyncSignedInUser());
    }
  } catch (error) {
    dispatch(setSigninData({ ...error, username: email }));
    const signupData = {
      username: email,
      isUserConfirmed: false
    };
    console.log('login fail signupData', signupData);
    dispatch(setSignupData(signupData));
  }
};

export const asyncResendConfirmCode = () => async (dispatch, getState) => {
  const { username } = getState().auth.signin;
  await Auth.resendSignUp(username);
  dispatch(push(routeUrl.verify, { fromAuthModal: true }));
  dispatch(setSigninData({}));
};

export const asyncSignedInUser = () => async dispatch => {
  try {
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: false,
    });
    const payload = {
      isUserSignedIn: true,
      signInUserSession: user.signInUserSession,
      username: user.username,
      attributes: user.attributes
    };
    dispatch(setSignedInUser(payload));
  } catch (error) {
    const payload = { isUserSignedIn: false };
    dispatch(setSignedInUser(payload));
  }
};

export const asyncUpdateUserAttributes = userDetails => async dispatch => {
  let user = await Auth.currentAuthenticatedUser();
  await Auth.updateUserAttributes(user, userDetails);

  const updatedUser = await Auth.currentAuthenticatedUser({
    bypassCache: true,
  });
  const payload = {
    isUserSignedIn: true,
    signInUserSession: updatedUser.signInUserSession,
    username: updatedUser.username,
    attributes: updatedUser.attributes
  };
  dispatch(setSignedInUser(payload));
};

export const asyncSignout = () => async dispatch => {
  await Auth.signOut();
  const payload = { isUserSignedIn: false };
  dispatch(setSignedInUser(payload));
};

const setSignedInUser = data => {
  const analytics = [];

  analytics.push({
    eventType: EventTypes.track,
    eventPayload: {
      event: data.isUserSignedIn ? 'User Signed In' : 'User Signed In Failed / Sign Out',
    },
  });

  if(data.isUserSignedIn){
    analytics.push({
      eventType: EventTypes.identify,
      eventPayload: {
        traits: data
      }
    });
  }

  return {
    type: SET_SIGNEDIN_USER,
    payload: data,
    meta: {
      analytics,
    },
  };
}

const setSignupData = data => ({
  type: SEND_SIGNUP_REQUEST,
  payload: data,
  meta: {
    analytics: [
      {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'User Signed Up',
        },
      },
      {
        eventType: EventTypes.identify,
        eventPayload: {
          traits: data
        }
    }],
  },
});

const setSigninData = data => ({
  type: SEND_SIGNIN_REQUEST,
  payload: data,
  meta: {
    analytics: [
      {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'Performed User Signed In Request',
        },
      },
    ],
  }
});

const setVerifyCodeError = data => ({
  type: VERIFY_CODE_ERROR,
  payload: data
});
