import * as React from "react";
import Form from "react-bootstrap/Form";
import { Field } from "formik";
import "./TextArea.scss";
const TextArea = ({
  name,
  label,
  type,
  placeholder,
  className,
  autoComplete,
  disabled
}) => {
  return (
    <Field
      name={name}
      render={props => {
        const {
          form: { errors, touched },
          field
        } = props;
        const showError = errors[name] && touched[name];
        return (
          <Form.Group className={`${className}`}>
            <Form.Label>{label}</Form.Label>
            <Form.Control
              {...field}
              name={name}
              label={label}
              placeholder={placeholder}
              type={type}
              as="textarea"
              isInvalid={showError}
              autoComplete={autoComplete || "off"}
              disabled={disabled || false}
            />
            <Form.Control.Feedback type="invalid">
              {errors[name]}
            </Form.Control.Feedback>
          </Form.Group>
        );
      }}
    />
  );
};

export default TextArea;
