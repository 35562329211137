import React, { useState } from "react";
import SolutionModal from "../SolutionModal";
import "./solutions.scss";
import SolutionButton from "../SolutionButton";
import { solutions } from "../../constants";

const Solutions = () => {
  const [isSolutionModalOpen, showSolutionModal] = useState(false);
  const [selectedSolution, selectSolution] = useState(null);

  const toggleModal = () => showSolutionModal(!isSolutionModalOpen);
  const onSelectSolution = solution => {
    window.analytics.track('Solution Selected', {solution});
    selectSolution(solution);
    toggleModal();
  };

  return (
    <section id="solutions" className="md-fluid-section  md-text-center-mob">
      <div className="container px-md-0">
        <div className="align-items-center row pt-5 vh-100">
          <div className="col-md-5">
            <h1 className="font-weight-bold mb-4">
              Supporting sustainable exploration,
              development, production, transportation,<br />
            storage and trading of power and energy - Energy Argnostic
            </h1>
            <small className="d-block mb-4 mt-4">
              We leverage our energy and power industry rich domain expertise, big data, IIoT, computer vision, robotics, custom hardware and proprietary
              <br />
              algorithms in unpredictable environments to:
            </small>
            <small className="d-block">
             &bull; provide strategic competitive advisory services
            </small>
            <small className="d-block">
              &bull; deliver top notch end to end solutions
            </small>
          </div>
          <div className="col-md-7">
            <div className="row">
              <div className="col-md-6 mt-5">
                <div className="md-product-item">
                  <label className="font-weight-bold text-uppercase m-0">
                    POWER | Energy
                  </label>
                  <p>
                    Simplify end to end Power and Energy Assets Portfolio Management in
                    complex and dynamic market condition...
                  </p>
                  <SolutionButton
                    solution={solutions.energy}
                    onSelectSolution={onSelectSolution}
                  />
                </div>
                <div className="md-product-item">
                  <label className="font-weight-bold text-uppercase m-0">
                    Industrial | Commercial
                  </label>
                  <p>
                    Take advantage of value derived from continuos deployment of
                    algorithm on the edge of IIoT devices...
                  </p>
                  <SolutionButton
                    solution={solutions.industrial}
                    onSelectSolution={onSelectSolution}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="md-product-item">
                  <label className="font-weight-bold text-uppercase m-0">
                    Digital Transformation
                  </label>
                  <p>
                    Improve productivity, better communication, quicker decision
                    making, and reduce compliance costs...
                  </p>
                  <SolutionButton
                    solution={solutions.transformation}
                    onSelectSolution={onSelectSolution}
                  />
                </div>
                <div className="md-product-item">
                  <label className="font-weight-bold text-uppercase m-0">
                    Specialized Training
                  </label>
                  <p>
                    Industry domain based AI, Big Data, IIoT and Robotics
                    Training for Technical and Management staffs...
                  </p>
                  <SolutionButton
                    solution={solutions.training}
                    onSelectSolution={onSelectSolution}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isSolutionModalOpen && (
        <SolutionModal
          toggleModal={toggleModal}
          selectedSolution={selectedSolution}
        />
      )}
    </section>
  );
};

export default Solutions;
