import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TextField from "../TextField";
import PrimaryButton from "../PrimaryButton";
import EditProfilePic from "../EditProfilePic";

const EditProfileSchema = Yup.object().shape({
  name: Yup.string().required("Name is Required")
});

const EditProfileForm = props => (
  <Formik
    initialValues={props.userDetails}
    validationSchema={EditProfileSchema}
    onSubmit={values => {
      // same shape as initial values
      props.updateProfile(values);
    }}
  >
    {({ errors, touched, values, setFieldValue }) => (
      <Form autoComplete="off">
        <div className="md-page-body row">
          <div className="col-lg-2">
            <EditProfilePic
              value={values.picture}
              onImageUpload={value => setFieldValue("picture", value)}
            />
          </div>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-md-6">
                <TextField
                  className="hd-form-field hd-light-field"
                  name="name"
                  type="text"
                  label="Name"
                  placeholder="Name"
                  autoComplete="on"
                />
              </div>
              <div className="col-md-6">
                <TextField
                  className="hd-form-field hd-light-field"
                  name="email"
                  type="text"
                  label="E-mail address"
                  placeholder="E-mail address"
                  disabled={true}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  className="hd-form-field hd-light-field"
                  name="phone_number"
                  type="text"
                  label="Phone Number"
                  placeholder="phone number"
                  disabled={true}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  className="hd-form-field hd-light-field"
                  name="profile"
                  type="text"
                  label="About Me"
                  placeholder="About me"
                />
              </div>
              <div className="col-12 text-center mt-3">
                <PrimaryButton className="hb-btn-small" type="submit">
                  Save
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </Form>
    )}
  </Formik>
);

export default EditProfileForm;
