import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const BlogDeleteModal = ({ postId, toggleModal, onDeletePost }) => {
  return (
    <Modal show={true} onHide={toggleModal}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Post</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this post</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={toggleModal}>
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={event => {
            onDeletePost(postId);
            toggleModal();
            event.stopPropagation();
          }}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BlogDeleteModal;
