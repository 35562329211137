// WARNING: DO NOT EDIT.  This file is automatically generated
// Written by aws-amplify-serverless-plugin/1.4.1 on 2020-09-05T21:25:03.028Z

const awsmobile = {
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_appsync_graphqlEndpoint: 'https://ydw7tkr2yneo7od2xc3vek23mq.appsync-api.us-east-1.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-1',
    aws_cognito_identity_pool_id: 'us-east-1:ac70bdfc-2bbd-4300-832c-e24c12522609',
    aws_cognito_region: 'us-east-1',
    aws_project_region: 'us-east-1',
    aws_user_files_s3_bucket: 'hd-web-api-prod-attachmentsbucket-kzcaggbmzlm1',
    aws_user_files_s3_bucket_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_PHqGhwJ9j',
    aws_user_pools_web_client_id: 'm4d8rai386bequq0n1ktu26af'
};

export default awsmobile;
