import React from "react";

const Training = () => (
  <div>
    <div className="modal-header">
      <h1 className="modal-title">Specialized Training</h1>
    </div>
    <div className="modal-body">
      <div className="text-left mb-5">
        <ul className="rect-green-list-style p-0 m-0">
          <li className="mb-2">
            Industry domain based AI, Big Data, IIoT and Robotics Training for
            Technical and Management staffs
          </li>
          <br/>
          <br/>
          <li className="mb-2">
            Industry specific use cases applied with data provided by us or you
          </li>
          <br/>
          <br/>
          <li className="mb-2">Training on site or remotely</li>
        </ul>
      </div>
    </div>
  </div>
);

export default Training;
