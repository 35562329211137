import React from "react";
import { connect } from "react-redux";
import EditProfileForm from "../components/EditProfileForm";
import { asyncUpdateUserAttributes } from "../actions/auth";

const EditProfileFormContainer = ({ updateProfile, ...restProps }) => {
  return (
    <EditProfileForm updateProfile={updateProfile} userDetails={restProps} />
  );
};

function mapStateToProps({ auth }) {
  return {
    name: auth.signedInUser.attributes.name,
    email: auth.signedInUser.attributes.email,
    phone_number: auth.signedInUser.attributes.phone_number,
    picture: auth.signedInUser.attributes.picture || "",
    profile: auth.signedInUser.attributes.profile || ""
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateProfile: userDetails =>
      dispatch(asyncUpdateUserAttributes(userDetails))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProfileFormContainer);
