import store from "../store";
import { setActiveUrlHash } from "../actions/app";

export function createObserver() {
  const intersectionObserverOptions = {
    root: null,
    rootMargin: "-20px 0px 20px",
    threshold: [0.9, 0.1]
  };
  const previousY = {};
  let previousRatio = {};
  function onIntersection(entries) {
    entries.forEach(entry => {
      const section = entry.target.id;
      const currentY = entry.boundingClientRect.y;
      const currentRatio = entry.intersectionRatio;

      if (currentRatio > previousRatio[section]) {
        if (
          (currentY < previousY[section] && currentRatio > 0.8) ||
          (currentY > previousY[section] && currentRatio < 0.3) ||
          (entry.target.id === "home" && currentY === 0)
        ) {
          store.dispatch(setActiveUrlHash(entry.target.id));
        }
      }

      previousRatio[section] = currentRatio;
      previousY[section] = currentY;
    });
  }

  const observer = new IntersectionObserver(
    onIntersection,
    intersectionObserverOptions
  );

  return observer;
}
