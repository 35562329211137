import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { app } from "./app";
import { auth } from "./auth";

export default history =>
  combineReducers({
    app,
    auth,
    router: connectRouter(history)
  });
