import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import TextField from "../TextField";
import { routeUrl } from "../../constants/";
import PrimaryButton from "../PrimaryButton";
import SocialAuthButtons from "../SocialAuthButtons";
import SigninError from "../../container/SigninError";

const SigninSchema = Yup.object().shape({
  email: Yup.string().required("Required"),
  password: Yup.string()
    .min(8)
    .max(16)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,}/,
      "Password is invalid"
    )
    .required()
});

const SigninForm = ({ asyncSignin, showError, signinError }) => (
  <div>
    <div className="modal-header">
      <h1 className="modal-title">Sign In</h1>
    </div>
    <Formik
      initialValues={{
        email: "",
        password: ""
      }}
      validationSchema={SigninSchema}
      onSubmit={values => {
        asyncSignin(values);
      }}
    >
      {({ errors, touched, submitCount }) => (
        <div className="modal-body p-0">
          <Form className="my-3">
            <TextField
              className="hd-form-field hd-bottom-border-field hd-no-label"
              name="email"
              type="text"
              placeholder="E-mail address"
            />
            <TextField
              className="hd-form-field hd-bottom-border-field hd-no-label"
              name="password"
              type="password"
              placeholder="Password"
            />
            <div className="d-flex justify-content-between align-items-center">
              <PrimaryButton className="hd-btn-large" type="submit">
                Sign in
              </PrimaryButton>
              <Link to="">Forgot Password ?</Link>
            </div>
          </Form>
          {submitCount > 0 && <SigninError />}
          <div className="text-center pt-2 mb-3">
            <Link
              className="hd-anchor-md"
              to={{
                search: routeUrl.signup
              }}
            >
              Don’t have account? Sign up
            </Link>
          </div>
          <SocialAuthButtons />
        </div>
      )}
    </Formik>
  </div>
);

export default SigninForm;
