import React from "react";
import BlogForm from "../BlogForm";
import CopyrightLabel from "../CopyrightLabel";

const EditBlog = ({ editPost, formValues }) => (
  <section className="md-fluid-section md-page-section md-default-section md-default-section-right-bg">
    <div className="container px-md-0">
      <div className="flex-column row mx-md-5 px-md-3">
        <div className="md-page-title text-center mt-4 mb-4">
          <h1>Edit post</h1>
        </div>
        <div className="md-page-body">
          <BlogForm handleSubmit={editPost} formValues={formValues} />
        </div>
        <div className="text-center">
          <CopyrightLabel />
        </div>
      </div>
    </div>
  </section>
);

export default EditBlog;
