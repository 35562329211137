import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TextField from "../TextField";
import PrimaryButton from "../PrimaryButton";
import VerifyCodeError from "../../container/VerifyCodeError";

const UserAuthorizeSchema = Yup.object().shape({
  code: Yup.string().required("Code is Required")
});

const UserAuthorizeForm = props => (
  <div className="md-center-form">
    <div className="modal-header justify-content-center">
      <h1 className="modal-title">Authentication code</h1>
    </div>
    <Formik
      initialValues={{
        code: ""
      }}
      validationSchema={UserAuthorizeSchema}
      onSubmit={values => {
        props.asyncUserAuthorize(values.code);
      }}
    >
      {({ errors, touched, submitCount }) => (
        <div className="modal-body p-0 text-center">
          <Form className="my-3" autoComplete="off">
            <label className="font-weight-bold mt-2 mb-4">
              Please check your phone for authentication code.
            </label>
            <TextField
              className="hd-form-field hd-bottom-border-field hd-no-label"
              name="code"
              type="text"
              placeholder="Authentication Code"
            />
            <div className="d-flex justify-content-center align-items-center pt-2">
              <PrimaryButton type="submit">Authenticate</PrimaryButton>
            </div>
          </Form>
          {submitCount > 0 && <VerifyCodeError />}
        </div>
      )}
    </Formik>
  </div>
);

export default UserAuthorizeForm;
