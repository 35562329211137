import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import TextField from "../TextField";
import { routeUrl } from "../../constants/";
import PrimaryButton from "../PrimaryButton";
import SocialAuthButtons from "../SocialAuthButtons";
import SignupError from "../../container/SignupError";

const SignupSchema = Yup.object().shape({
  name: Yup.string().required("Name is Required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Email is Required"),
  phoneNumber: Yup.string().required("Phone Number is required"),
  password: Yup.string()
    .min(8)
    .max(16)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,}/,
      "Password is invalid"
    )
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password Confirm is required")
});

const SignupForm = props => (
  <div>
    <div className="modal-header">
      <h1 className="modal-title">Sign Up</h1>
    </div>
    <Formik
      initialValues={{
        name: "",
        email: "",
        phoneNumber: "",
        password: "",
        confirmPassword: ""
      }}
      validationSchema={SignupSchema}
      onSubmit={values => {
        props.asyncSignup(values);
      }}
    >
      {({ errors, touched, submitCount }) => (
        <div className="modal-body p-0">
          <Form className="my-3" autoComplete="off">
            <TextField
              className="hd-form-field hd-bottom-border-field hd-no-label"
              name="name"
              type="text"
              placeholder="Name"
              autoComplete="on"
            />
            <TextField
              className="hd-form-field hd-bottom-border-field hd-no-label"
              name="email"
              type="text"
              placeholder="E-mail address"
              autoComplete="on"
            />
            <TextField
              className="hd-form-field hd-bottom-border-field hd-no-label"
              name="phoneNumber"
              type="text"
              placeholder="Phone number"
              autoComplete="on"
            />
            <TextField
              className="hd-form-field hd-bottom-border-field hd-no-label"
              name="password"
              type="password"
              placeholder="Password"
            />
            <TextField
              className="hd-form-field hd-bottom-border-field hd-no-label"
              name="confirmPassword"
              type="password"
              placeholder="Re-enter password"
            />
            <div className="d-flex justify-content-between align-items-center">
              <PrimaryButton className="hd-btn-large" type="submit">
                Sign up
              </PrimaryButton>
              <Link
                to={{
                  search: routeUrl.signin
                }}
              >
                Already member? Sign in
              </Link>
            </div>
          </Form>
          {submitCount > 0 && <SignupError />}
          <div className="md-terms-message pt-2 mb-3 pb-2">
            By registerating you’re agree to{" "}
            <Link to="#" target="_blank">terms of use</Link> and{" "}
            <Link to="#" target="_blank">privacy policy</Link>
          </div>
          <SocialAuthButtons />
        </div>
      )}
    </Formik>
  </div>
);

export default SignupForm;
