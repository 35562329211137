import dayjs from "dayjs";
export const formatDate = timestamp => {
  const date = dayjs(timestamp * 1000);
  const year = date.format("YYYY");
  const showYear = dayjs().format("YYYY") !== year;
  const dayMonth = date.format("D MMM");
  const time = date.format("h:mm a");
  return `${dayMonth} ${showYear ? year : ""} ${time}`;
};

export const calculateReadingTime = text => {
  const wordsPerMinute = 200;
  const noOfWords = text.split(/\s/g).length;
  const minutes = noOfWords / wordsPerMinute;
  return Math.ceil(minutes);
};
