import headerModal from "./headerModal";
const { signin, signup, queryParam, verify, authorize } = headerModal;
export default {
  base: "/",
  home: "/#home",
  solutions: "/#products",
  products: "/#products",
  presentation: "/#products",
  contact: "/#contact",
  blog: "/rail",
  tollIts: '/its',
  energyEfficiency: '/ev_energy',
  finTech: '/fintech',
  energyMarkets: '/energy_markets',
  gasMarkets: '/gas_markets',
  myBlog: "/blog/me",
  blogDetail: "/blog/:id",
  createBlog: "/blog/create",
  editBlog: "/blog/:id/edit",
  editProfile: "/editprofile",
  signin: `?${queryParam}=${signin}`,
  signup: `?${queryParam}=${signup}`,
  verify: `?${queryParam}=${verify}`,
  authorize: `?${queryParam}=${authorize}`
};
