import React from "react";
import gql from "graphql-tag";
import ContactUsForm from "../components/ContactUsForm";
import { Mutation } from "react-apollo";

const submitContactForm = gql`
  mutation($form: ContactFormInput!) {
    contactForm(form: $form) {
      id
    }
  }
`;

const ContactUsFormContainer = () => {
  return (
    <Mutation mutation={submitContactForm}>
      {(submitForm, { data }) => <ContactUsForm submitForm={submitForm} />}
    </Mutation>
  );
};

export default ContactUsFormContainer;
