import React from "react";
import { connect } from "react-redux";
import PrivateRoute from "../components/PrivateRoute";

const PrivateRouteContainer = props => {
  return <PrivateRoute {...props} />;
};

function mapStateToProps({ auth }) {
  return {
    isUserSignedIn: auth.signedInUser.isUserSignedIn
  };
}

export default connect(mapStateToProps)(PrivateRouteContainer);
