import React, { useEffect } from "react";
import { Query } from "react-apollo";
import BlogDetails from "../components/BlogDetails";
import { getPost } from "../queries/posts";
import { connect } from "react-redux";
import { createMatchSelector } from "connected-react-router";
import { routeUrl } from "../constants";
// import { API, graphqlOperation } from "aws-amplify";

const BlogDetailsContainer = props => {
  // API integeration for blog view count
  // useEffect(() => {
  //   // Pass variables in mutation if necessary or use Query
  //   const updateViewCount = `mutation UpdateViewCount() {
  //     updateViewCount() {
  //         views
  //     }
  //   }`;
  //   API.graphql(graphqlOperation(updateViewCount));
  // }, []);

  const matchSelector = createMatchSelector({ path: routeUrl.blogDetail });
  const match = matchSelector({ router: props.router });
  if (!match) {
    return null;
  }
  const id = match.params.id;
  return (
    <Query
      query={getPost}
      variables={{ id: id }}
      fetchPolicy="cache-and-network"
    >
      {({ loading, error, data }) => {
        if (loading || error) {
          return null;
        }

        const blogDetails = data.getPost;
        return <BlogDetails blogDetails={blogDetails} />;
      }}
    </Query>
  );
};

function mapStateToProps({ app, router, auth }) {
  return {
    router: router
  };
}

export default connect(mapStateToProps)(BlogDetailsContainer);
