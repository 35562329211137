import React from "react";
import { Link } from "react-router-dom";
import { routeUrl } from "../../constants/";
import "./bullets.scss";

const Bullets = ({ activePath }) => {
  return (
    <section id="bullets" className="position-fixed d-flex flex-column align-items-center">
        <Link
            className={routeUrl.home === activePath ? "active" : ""}
            to={routeUrl.home}>
        </Link>
        <Link
            className={routeUrl.products === activePath ? "active" : ""}
            to={routeUrl.products}>
        </Link>
        <Link
            className={
            routeUrl.presentation === activePath ? "active" : ""}
            to={routeUrl.presentation}>
        </Link>
        <Link
            className={routeUrl.company === activePath ? "active" : ""}
            to={routeUrl.company}>
        </Link>
    </section>
  );
};

export default Bullets;
