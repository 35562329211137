import React from "react";
import { connect } from "react-redux";
import Bullets from "../components/Bullets";

const BulletsContainer = props => {
  const { activeUrlHash } = props;
  let activePath = `/#${activeUrlHash}`;

  return <Bullets activePath={activePath} />;
};

function mapStateToProps({ app, router }) {
  return {
    activeUrlHash: app.activeUrlHash
  };
}

export default connect(mapStateToProps)(BulletsContainer);
