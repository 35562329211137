import React, { useState, useEffect } from "react";
import { ReactSlackChat } from "react-slack-chat";
import publicIp from "public-ip";
import teamIcon from "../../assets/images/logo.png";
import "./slackChat.scss";

const SlackChat = () => {
  const [ip, setIp] = useState(null);

  useEffect(() => {
    publicIp.v4().then(ip => setIp(ip));
  }, []);

  const getClientID = ip;
  const getClientAvatar = `https://robohash.org/${getClientID}`;
  return (
    <ReactSlackChat
      botName={getClientID}
      apiToken="eG94Yi01NDYxNzQ3NDkzNDUtNTQ4MjAzMjEzNTU4LWh1Z3oyUzZreEplN253MTdveU1GYWdUTQ=="
      channels={[
        {
          name: "customer-success",
          icon: teamIcon
        }
      ]}
      helpText="Chat with us"
      defaultChannel={"customer-success"}
      userImage={getClientAvatar}
      debugMode={true}
      closeChatButton={true}
      defaultMessage="Hello :), how may we help you?"
      singleUserMode={true}
    />
  );
};

export default SlackChat;
