import React from "react";
import CopyrightLabel from "../CopyrightLabel";
import EditProfileForm from "../../container/EditProfileForm";

const EditProfile = props => (
  <section className="md-fluid-section md-page-section md-default-section md-default-section-left-bg">
    <div className="container px-md-0">
      <div className="flex-column mx-md-5 px-md-3">
        <div className="md-page-title text-center mb-4 mt-md-4">
          <h1>Edit Profile</h1>
        </div>
        <EditProfileForm />
        <div className="text-center">
          <CopyrightLabel />
        </div>
      </div>
    </div>
  </section>
);

export default EditProfile;
