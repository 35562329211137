import React from "react";

const Transformation = () => (
  <div>
    <div className="modal-header">
      <h1 className="modal-title">Digital Transformation</h1>
    </div>
    <div className="modal-body">
      <div className="text-left mb-5">
        <ul className="rect-green-list-style p-0 m-0">
          <li className="mb-2">
            Improve productivity, better communication, quicker decision making,
            and reduce compliance costs with our managed hosted<br/> energy data
            search and Knowledge Discovery service
          </li>
          <br/>
          <br/>
          <li className="mb-2">
            Provide hands-on technical and business advice on the most cost
            effective production grade technology that suites your use case
          </li>
          <br/>
          <br/>
          <li className="mb-2">
            Maintain compliance with regulations such as HIPAA, Sarbanes-Oxley
            and more effortlessly
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default Transformation;
