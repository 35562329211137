import React from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton
} from "react-share";
const ShareArticle = () => {
  const shareUrl = window.location.href;
  return (
    <ul className="no-list-style d-flex">
      <li className="font-weight-bold">Share article</li>
      <li>
        <LinkedinShareButton url={shareUrl}>
          <a className="hd-icon-linkedin" href="#" />
        </LinkedinShareButton>
      </li>
      <li>
        <FacebookShareButton url={shareUrl}>
          <a className="hd-icon-facebook" href="#" />
        </FacebookShareButton>
      </li>
      <li>
        <TwitterShareButton url={shareUrl}>
          <a className="hd-icon-twitter" href="#" />
        </TwitterShareButton>
      </li>
    </ul>
  );
};

export default ShareArticle;
