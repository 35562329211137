import React, { useState } from "react";
import ProductModal from "../ProductModal";
import "./products.scss";
import ProductButton from "../ProductButton";
import { products } from "../../constants";
const Products = () => {
  const [isProductModalOpen, showProductModal] = useState(false);
  const [selectedProduct, selectProduct] = useState(null);

  const toggleModal = () => showProductModal(!isProductModalOpen);
  const onSelectProduct = product => {
    window.analytics.track("Product Selected", { product });
    if (product === "smart-agent") {
      window.open("https://hybridata.us/energy_markets");
    } 
    if (product === "energy-vision") {
      window.open("https://hybridata.us/ev_energy");
    } 
    if (product === "technical-advisor") {
      window.open("https://hybridata.us/fintech");
    } 
    // else {
    //   selectProduct(product);
    //   toggleModal();
    // }
  };

  return (
    <section id="products" className="md-fluid-section px-md-0">
      <div className="container d-flex align-items-center position-relative zi-1 vh-100 w-100">
        <div className="row">
          <div className="col-12 text-center">
            <h1>Products and Specialized Services</h1>
          </div>
          <div
            id="md-solution-step3"
            className="col-md-4 md-solution-item d-flex flex-column align-items-center text-center justify-content-start"
          >
            <div className="md-solution-item-avatar" />
            <label>Project Finance and Energy Trading</label>
            <p>
              Mitigate market risks and optimize the value of battery storage, solar, wind, and thermal power assets — both pre and post COD
              using our advanced cloud-based risk management platform.
            </p>
            <ProductButton
              product={products.smartAgent}
              onSelectProduct={onSelectProduct}
            />
          </div>
          <div
            id="md-solution-step2"
            className="col-md-4 md-solution-item d-flex flex-column align-items-center text-center justify-content-start"
          >
            <div className="md-solution-item-avatar" />
            <label>EV Infrastructure Siting</label>
            <p>
              Utilize analytics powered by big data combined with electrical engineering expertise to streamline and accelerate EVSE installation and permitting.
              Achieve substantial cost and time savings with our cutting-edge, cloud-native analytics platform.
            </p>
            <ProductButton
              product={products.energyVision}
              onSelectProduct={onSelectProduct}
            />
          </div>
          <div
            id="md-solution-step4"
            className="col-md-4 md-solution-item d-flex flex-column align-items-center text-center justify-content-start"
          >
            <div className="md-solution-item-avatar" />
            <label>Financial Services + FinTech</label>
            <p>
              Collaborate with a trusted fintech implementation and consulting partner to not only match 
              but surpass the innovation speed of leading financial institutions.
            </p>
            <ProductButton
              product={products.technicalAdvisor}
              onSelectProduct={onSelectProduct}
            />
          </div>
          {/* <div
            id="md-solution-step1"
            className="col-md-3 md-solution-item d-flex flex-column align-items-center text-center justify-content-start"
          >
            <div className="md-solution-item-avatar" />
            <label> Directional Drilling + Geosteering</label>
            <p>
              Expedite and realize Autonomous Drilling projects leveraging the self driving car technology stack, machine intelligence, advanced control strategies, and industrial hardware electronics
            </p>
            <ProductButton
              product={products.selfDriving}
              onSelectProduct={onSelectProduct}
            />
          </div> */}
        </div>
      </div>
      {isProductModalOpen && (
        <ProductModal
          toggleModal={toggleModal}
          selectedProduct={selectedProduct}
        />
      )}
    </section>
  );
};

export default Products;
