import { EventTypes } from 'redux-segment';
export const SET_ACTIVE_PAGE = "SET_ACTIVE_PAGE";

export const setActiveUrlHash = currentPage => ({
  type: SET_ACTIVE_PAGE,
  value: currentPage,
  meta: {
    analytics: {
      eventType: EventTypes.page,
      eventPayload: {
        name: currentPage
      }
    }
  }
});
