import React, { useState } from "react";
import { FormLabel, Spinner } from "react-bootstrap";
import "./featuredImage.scss";
import { Storage } from "aws-amplify";

const FeatureImage = ({ onImageUpload, touched, error, value }) => {
  const defaultFileName = value.substring(value.lastIndexOf("/") + 1);
  const [fileName, setFilename] = useState(defaultFileName);
  const [isLoading, setLoading] = useState(false);

  const onChange = async e => {
    setLoading(true);
    const file = e.target.files[0];
    const filename = file.name;
    try {
      const { key } = await Storage.put(
        `${Date.now()}-${filename.split(" ").join("_")}`,
        file,
        {
          customPrefix: { public: "blog/" },
          level: "public",
          contentType: file.type,
          ACL: "public-read"
        }
      );

      const imageUrl = await Storage.get(key, {
        customPrefix: { public: "blog/" },
        level: "public"
      });
      onImageUpload(imageUrl.split("?")[0]);
      setFilename(filename);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  return (
    <div className="mb-3">
      <FormLabel as="label" className="mb-2">
        Featured Image
      </FormLabel>
      <div className="custom-file d-flex justify-content-around">
        <input
          type="file"
          className="custom-file-input"
          id="image"
          accept="image/*"
          onChange={onChange}
          onClick={event => {
            event.target.value = null;
          }}
        />
        <label
          className="custom-file-label md-featured-image mb-0"
          htmlFor="customFile"
        >
          {fileName ? fileName : "Select files"}
        </label>
      </div>
      {isLoading && <Spinner animation="border" size="sm" />}
      {error && touched && (
        <div className="invalid-feedback d-block">{error}</div>
      )}
    </div>
  );
};

export default FeatureImage;
