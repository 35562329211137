import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { routeUrl } from "../../constants/";
import "./header.scss";
import AuthMenuItems from "../AuthMenuItems";
import ProfileDropdown from "../../container/ProfileDropdown";

const Header = ({ activePath, isBlackBackground, isUserSignedIn }) => {
  return (
    <section className={
      "fixed-top hd-nav-wrap w-100 " + (isBlackBackground ? "hd-light-nav" : "")
    }
    >
      <div className="container px-md-0">
        <Navbar collapseOnSelect className="d-flex justify-content-between" expand="lg">
          <div className="hd-logo">
            <Navbar.Brand as={Link} to={routeUrl.home} className="m-0 p-0">
              <img src={logo} alt="HybriData logo" />
              hybriData
            </Navbar.Brand>
          </div>
          <div className="hd-nav d-flex align-items-center">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav>
                <Link
                  className={
                    "nav-item " + (routeUrl.home === activePath ? "active" : "")
                  }
                  to={routeUrl.home}
                >
                  Home
                </Link>
                <Link
                  className={
                    "nav-item " +
                    (routeUrl.presentation === activePath ? "active" : "")
                  }
                  to={routeUrl.presentation}
                >
                  Services
                </Link>
                  <Link
                  className={
                    "nav-item " +
                    (routeUrl.products === activePath ? "active" : "")
                  }
                  to={routeUrl.products}
                >
                  Solutions
                </Link>
                <NavDropdown id='capabilities-dropdown'
                    className={[routeUrl.energyMarkets, routeUrl.blog, routeUrl.gasMarkets, routeUrl.tollIts, routeUrl.energyEfficiency].includes(activePath) ? "active" : ""}
                    title='More'>
                    <NavDropdown.Item>
                      <Link
                        className={
                          "nav-item " +
                          (routeUrl.energyEfficiency === activePath ? "active" : "")
                        }
                        to={routeUrl.energyEfficiency}
                      >
                        EV + Energy Efficiency
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Link
                        className={
                          "nav-item " +
                          (routeUrl.gasMarkets === activePath ? "active" : "")
                        }
                        to={routeUrl.gasMarkets}
                      >
                        Natural Gas Markets
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Link
                        className={
                          "nav-item " +
                          (routeUrl.blog === activePath ? "active" : "")
                        }
                        to={routeUrl.blog}
                      >
                        Rail
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Link
                        className={
                          "nav-item " +
                          (routeUrl.tollIts === activePath ? "active" : "")
                        }
                        to={routeUrl.tollIts}
                      >
                        Toll, ITS, AV/CV
                      </Link>
                    </NavDropdown.Item>
                  </NavDropdown>
                <Link
                  className={
                    "nav-item " +
                    (routeUrl.contact === activePath ? "active" : "")
                  }
                  to={routeUrl.contact}
                >
                  Contact
                </Link>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </div>
    </section>
  );
};

export default Header;
