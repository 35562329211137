import React from "react";

const TechnicalAdvisor = () => (
  <div>
    <div className="modal-header">
      <h1 className="modal-title">
        <span className="primary-text-color">hD</span> Power + Energy Advisor
      </h1>
    </div>
    <div className="modal-body">
      <div className="hd-modal-detail-bullet text-left mb-5">
        <ul className="rect-green-list-style p-0 m-0">
          <li className="mb-2">
            Demand Flexibility and Capacity for Wind, Solar, Natural Gas, Geothermal and Hydro Power Plants
          </li>
          <br/>
          <br/>
            <li className="mb-2">
              Transportation and Mobility Electrification Projects
            </li>
            <br/>
          <br/>
          <li className="mb-2">
            Industrial Power Electronics for Clean Energy projects
          </li>
          <br/>
          <br/>
          <li className="mb-2">Oil and Gas Asset valuation - M&amp;A , A&amp;D</li>
        </ul>
      </div>
      <br/>
      <br/>
      
      <div className="hd-modal-detail-bullet-sm text-left">
        <label className="m-0">Sources:</label>
        <ul className="no-list-style p-0 m-0">
          <li className="md-break-word">
            <a href="https://www.slideshare.net/CJEjimuda/internet-of-energy-can-python-prevent-california-wildfires">
              https://www.slideshare.net/CJEjimuda/internet-of-energy-can-python-prevent-california-wildfires
            </a>
          </li>
          <li className="md-break-word">
            <a href="http://www.searchanddiscovery.com/pdfz/documents/2018/42234ejimuda/ndx_ejimuda.pdf.html">
              http://www.searchanddiscovery.com/pdfz/documents/2018/42234ejimuda/ndx_ejimuda.pdf.html
            </a>
          </li>
          <li className="md-break-word">
            <a href="https://www.slideshare.net/CJEjimuda/optimizing-pv-energy-yield-with-elasticsearch-and-graphql">
              https://www.slideshare.net/CJEjimuda/optimizing-pv-energy-yield-with-elasticsearch-and-graphql
            </a>
          </li>
          <li className="md-break-word">
            <a href="https://www.slideshare.net/CJEjimuda/aapg-geoscience-technology-workshop-2019">
              https://www.slideshare.net/CJEjimuda/aapg-geoscience-technology-workshop-2019
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default TechnicalAdvisor;
