import React from "react";
import { Link } from "react-router-dom";
import { routeUrl } from "../../constants/";
import { Dropdown } from "react-bootstrap";
import "./profileDropdown.scss";

const ProfileDropdown = ({ username, picture, asyncSignout }) => {
  return (
    <Dropdown className="dropdown md-profile-dropdown">
      <Dropdown.Toggle
        className={
          "rounded-circle dropdown-toggle " + (picture ? "md-avatar-available" : "")
        }
        type="button"
        id="md-profileDropdown"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        style={{backgroundImage: `url(${picture})`}}
      >
        {!picture && <i className="hd-icon-user"></i>}
      </Dropdown.Toggle>
      <Dropdown.Menu
        alignRight
        className="dropdown-menu"
        aria-labelledby="md-profileDropdown"
      >
        <Dropdown.Item>
         <Link className="nav-item" to={routeUrl.editProfile}>Edit Profile</Link>
        </Dropdown.Item>

        <Dropdown.Item>
          <button className="nav-item" onClick={asyncSignout}>Sign Out</button>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
