import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { API, graphqlOperation } from "aws-amplify";
import BlogCard from "../components/BlogCard";
import { deletePost } from "../queries/posts";

const BlogCardContainer = props => {
  const onDeletePost = async id => {
    window.analytics.track('Blog Post Delete', {id});
    await API.graphql(
      graphqlOperation(deletePost, {
        id: id
      })
    );
    await props.refetchBlogList();
  };
  return <BlogCard {...props} onDeletePost={onDeletePost} />;
};

function mapStateToProps({ router }) {
  return {
    pathname: router.location.pathname
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onClick: id => dispatch(push(`/blog/${id}`)),
    onClickEdit: id => dispatch(push(`/blog/${id}/edit`))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlogCardContainer);
