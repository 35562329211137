import { SET_ACTIVE_PAGE } from "../actions/app";

export const initialState = {
  activeUrlHash: "home"
};

export const app = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_PAGE:
      return { ...state, activeUrlHash: action.value };
    default:
      return state;
  }
};
