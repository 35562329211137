import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import TextField from "../TextField";
import PrimaryButton from "../PrimaryButton";
import VerifyCodeError from "../../container/VerifyCodeError";

const UserConfirmSchema = Yup.object().shape({
  code: Yup.string().required("Code is Required")
});

const UserConfirmForm = props => (
  <div className="md-center-form">
    <div className="modal-header justify-content-center">
      <h1 className="modal-title">Verification code</h1>
    </div>
    <Formik
      initialValues={{
        code: ""
      }}
      validationSchema={UserConfirmSchema}
      onSubmit={values => {
        props.asyncUserConfirm(values.code);
      }}
    >
      {({ errors, touched, submitCount }) => (
        <div className="modal-body p-0 text-center">
          <Form className="my-3" autoComplete="off">
            <label className="font-weight-bold mt-2 mb-4">
              Verify phone number you entered to make sure the phone is yours and vaild to get notification 
              and able to reset password with it.
            </label>
            <TextField
              className="hd-form-field hd-bottom-border-field hd-no-label"
              name="code"
              type="text"
              placeholder="Enter 6 digits code"
            />
            <div className="d-flex justify-content-center align-items-center pt-2">
              <PrimaryButton type="submit">Confirm</PrimaryButton>
            </div>
            <small className="d-block text-center mt-4 mb-2">
              didn't get code? 
              <Link
                className="font-weight-bold pl-2"
              >
                send again
              </Link>
            </small>
          </Form>
          {submitCount > 0 && <VerifyCodeError />}
        </div>
      )}
    </Formik>
  </div>
);

export default UserConfirmForm;
