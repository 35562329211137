import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { addPost } from "../queries/posts";
import CreateBlog from "../components/CreateBlog";
import { API, graphqlOperation } from "aws-amplify";

const CreateBlogContainer = ({ onPostCreated, authorName, authorImage }) => {
  const createPost = async post => {
    await API.graphql(
      graphqlOperation(addPost, { post: { ...post, authorName, authorImage } })
    );
    onPostCreated();
  };
  return <CreateBlog createPost={createPost} />;
};

function mapStateToProps({ auth }) {
  return {
    userId: auth.signedInUser.username,
    authorName: auth.signedInUser.attributes.name,
    authorImage:
      auth.signedInUser.attributes.picture ||
      "https://www.chaarat.com/wp-content/uploads/2017/08/placeholder-user.png"
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onPostCreated: id => dispatch(push(`/blog`))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateBlogContainer);
