import React from "react";
import { connect } from "react-redux";
import UserConfirmForm from "../components/UserConfirmForm";

import { asyncUserConfirm } from "../actions/auth";

const UserConfirmFormContainer = props => {
  const { asyncUserConfirm, signupData } = props;
  if (!signupData.username) {
    return null;
  }
  return <UserConfirmForm asyncUserConfirm={asyncUserConfirm} />;
};

function mapStateToProps({ auth }) {
  return {
    signupData: auth.signup
  };
}

function mapDispatchToProps(dispatch) {
  return {
    asyncUserConfirm: code => dispatch(asyncUserConfirm(code))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserConfirmFormContainer);
