import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { API, graphqlOperation } from "aws-amplify";
import { createMatchSelector } from "connected-react-router";
import { updatePost, getPost } from "../queries/posts";
import EditBlog from "../components/EditBlog";
import { routeUrl } from "../constants";
import { Query } from "react-apollo";

const EditBlogContainer = ({
  onPostEditd,
  authorName,
  authorImage,
  currentUserId,
  router
}) => {
  const matchSelector = createMatchSelector({ path: routeUrl.blogDetail });
  const match = matchSelector({ router: router });
  if (!match) {
    return null;
  }
  const postId = match.params.id;
  const editPost = async post => {
    window.analytics.track('Post Edited', {post});
    await API.graphql(
      graphqlOperation(updatePost, {
        post: { ...post, authorName, authorImage, id: postId }
      })
    );
    onPostEditd();
  };

  return (
    <Query
      query={getPost}
      variables={{ id: postId }}
      fetchPolicy="network-only"
    >
      {({ loading, error, data }) => {
        if (loading || error) {
          return null;
        }

        const blogDetails = data.getPost;
        const { title, userId, description, featureImage, tags } = blogDetails;
        if (currentUserId !== userId) {
          return null;
        }
        const formValues = {
          title,
          description,
          featureImage,
          tags: tags.map(val => ({ value: val, label: val }))
        };
        return <EditBlog editPost={editPost} formValues={formValues} />;
      }}
    </Query>
  );
};

function mapStateToProps({ auth, router }) {
  return {
    router: router,
    currentUserId: auth.signedInUser.username,
    authorName: auth.signedInUser.attributes.name,
    authorImage:
      auth.signedInUser.attributes.picture ||
      "https://www.chaarat.com/wp-content/uploads/2017/08/placeholder-user.png"
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onPostEditd: id => dispatch(push(`/blog`))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditBlogContainer);
