import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import "./editor.scss";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const ContentEditor = ({
  name,
  label,
  value,
  onBlur,
  onChange,
  touched,
  error
}) => {
  useEffect(() => {
    const blocksFromHTML = convertFromHTML(value);
    if (blocksFromHTML.contentBlocks) {
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(state));
    }
  }, []);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const changeEditorState = state => {
    let editorSourceHTML = draftToHtml(convertToRaw(state.getCurrentContent()));

    onChange(editorSourceHTML);
    setEditorState(state);
  };

  // const showError = errors[name] && touched[name];
  return (
    <Form.Group className="h-100">
      <Form.Label className="mb-2">{label}</Form.Label>
      <Editor
        value={value}
        editorState={editorState}
        toolbarClassName="md-editor-toolbar"
        wrapperClassName="md-editor-wrapper"
        editorClassName="md-editor-field"
        placeholder="Type here .."
        onEditorStateChange={changeEditorState}
        onBlur={onBlur}
      />
      {error && touched && (
        <div className="invalid-feedback d-block">{error}</div>
      )}
    </Form.Group>
  );
};

export default ContentEditor;
