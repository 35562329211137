import React from "react";


function Bizqualify (props){

return(
  <div style={{ display: "flex",justifyContent: "center"}}>
        <iframe src={props.pdfurl} width="960" height="800" allow="autoplay">
        </iframe>
  </div>
)

};

function energymarketBizQualify(){
  return(
      <Bizqualify pdfurl="https://drive.google.com/file/d/1rEqE0rLu6E0aqedKnX32CUJ_BQI09AMY/preview" />
  );
}

export default energymarketBizQualify;

