import React from "react";

const SolutionButton = ({ solution, onSelectSolution }) => {
  const onClick = () => onSelectSolution(solution);
  return (
    <button
      onClick={onClick}
      className="hd-btn hd-btn-small hd-btn-primary-hover"
    >
      Read more
    </button>
  );
};

export default SolutionButton;
