import React from "react";
import "./company.scss";
import CopyrightLabel from "../CopyrightLabel";
import ContactUsForm from "../../container/ContactUsForm";

const Company = () => {
  return (
    <section id="contact" className="md-fluid-section md-default-section md-text-center-mob">
      <div className="container px-md-0 position-relative zi-1">
        <div className="align-items-lg-center row vh-100">
          <div className="col-lg-5 company-left">
            <div>
              <div className="mb-4 pb-1">
                <label className="d-block mb-0">Call us:</label>
                <strong>+1 (213) 282-8273</strong>
              </div>
              <div>
                <label className="d-block mb-0">Follow us</label>
                <div className="hd-social">
                  <ul className="d-flex justify-content-center justify-content-lg-start no-list-style m-0 p-0">
                    <li>
                      <a
                        className="hd-icon-linkedin"
                        href="https://www.linkedin.com/company/hybridata/about/?viewAsMember=true"
                        target="_blank" />
                    </li>
                    <li>
                      <a className="hd-icon-youtube" href="https://www.youtube.com/channel/UCNY7RdcUyxz3YkixRKXZdJQ" target="_blank" />
                    </li>
                    <li>
                      <a
                        className="hd-icon-facebook"
                        href="https://www.facebook.com/HybriData-167734714109555"
                        target="_blank"
                      />
                    </li>
                    <li>
                      <a className="fa fa-instagram" href="https://www.instagram.com/hybridata/" target="_blank" />
                    </li>
                    <li>
                      <a className="hd-icon-twitter" href="https://twitter.com/hybridata_us" target="_blank" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* For Web View */}
            <CopyrightLabel className="hd-copyright-web" />
          </div>
          <div className="col-lg-7 hd-footer-bg-mob">
            <div className="company-right">
              <div className="hd-footer-nav mb-4">
                <ul className="no-list-style m-0 p-0">
                <li>
                  <a href="https://www.slideshare.net/CJEjimuda/presentations" target="_blank">
                    Presentations
                  </a>
                </li>
                <li>
                  <a href="https://www.slideshare.net/CJEjimuda/self-driving-directional-drilling-on-the-edge" target="_blank">
                    White Papers
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/channel/UCNY7RdcUyxz3YkixRKXZdJQ" target="_blank">
                    Videos
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/in/chijioke-cj-ejimuda-8273a55a/" target="_blank">
                    About us
                  </a>
                </li>
                <li>
                  <a href="https://www.onepetro.org/conference-paper/SPE-190036-MS" target="_blank">
                    Technical Papers
                  </a>
                </li>
                </ul>
              </div>
              <div className="hd-contact-form">
                <label className="mb-2">Start up a conversation:</label>
                <ContactUsForm />
              </div>
              {/* For Mobile View */}
              <CopyrightLabel className="d-none hd-copyright-mob mb-3" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Company;
