import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { createTracker } from 'redux-segment';

import createRootReducer from "./reducers";

export const history = createBrowserHistory();

const initialState = {};
const enhancers = [];
const tracker = createTracker();

const middleware = [thunk, tracker, routerMiddleware(history)];
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  middleware.push(logger);
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);

const store = createStore(
  createRootReducer(history),
  initialState,
  composedEnhancers
);

export default store;
