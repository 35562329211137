import React from "react";

const ProductButton = ({ product, onSelectProduct }) => {
  const onClick = () => onSelectProduct(product);
  return (
    <button onClick={onClick} className="hd-btn">
      Read more
    </button>
  );
};

export default ProductButton;
