import React from "react";
import Modal from "react-bootstrap/Modal";
import Energy from "../SolutionEnergy";
import { solutions } from "../../constants";
import "./solutionModal.scss";
import Transformation from "../SolutionTransformation";
import Industrial from "../SolutionIndustrial";
import Training from "../SolutionTraining";

const SolutionModal = ({ selectedSolution, toggleModal }) => {
  return (
    <Modal
      show
      size="solution-modal"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={toggleModal}
    >
      <button
        type="button"
        className="modal-close rounded-circle position-absolute hd-icon-close"
        onClick={toggleModal}
      />
      {
        {
          [solutions.energy]: <Energy />,
          [solutions.transformation]: <Transformation />,
          [solutions.industrial]: <Industrial />,
          [solutions.training]: <Training />
        }[selectedSolution]
      }
    </Modal>
  );
};

export default SolutionModal;
