import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";
import Form from "react-bootstrap/Form";
import "./tagSelect.scss";

const TagSelector = ({ name, label, value, onChange }) => {
  const [inputValue, setInputValue] = useState("");

  const handleTagsKeyDown = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setInputValue("");
        if (Array.isArray(value)) {
          onChange([...value, { value: inputValue, label: inputValue }]);
        } else {
          onChange([{ value: inputValue, label: inputValue }]);
        }
        event.preventDefault();
        break;
      default:
    }
  };
  return (
    <Form.Group>
      <Form.Label htmlFor="blogTags" className="mb-2">
        {label}
      </Form.Label>
      <CreatableSelect
        components={{
          DropdownIndicator: null
        }}
        classNamePrefix="md-tag-select"
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={onChange}
        onInputChange={value => setInputValue(value)}
        onKeyDown={handleTagsKeyDown}
        placeholder="add tags"
        value={value}
      />
    </Form.Group>
  );
};

export default TagSelector;
