import React from "react";
import "./signinError.scss";
const SigninError = ({ signinError, asyncResendConfirmCode }) => (
  <div className="invalid-feedback d-inline-block">
    {signinError.message}
    {signinError.code === "UserNotConfirmedException" && (
      <label onClick={asyncResendConfirmCode}>Verify now</label>
    )}
  </div>
);

export default SigninError;
