import React, { useState } from "react";
import { Storage } from "aws-amplify";

import "./editProfilePic.scss";
import { Spinner } from "react-bootstrap";

const EditProfilePic = ({ onImageUpload, value }) => {
  // const defaultFileName = value.substring(value.lastIndexOf("/") + 1);
  const [isLoading, setLoading] = useState(false);

  const onChange = async e => {
    setLoading(true);
    const file = e.target.files[0];
    const filename = file.name;
    try {
      const { key } = await Storage.put(
        `${Date.now()}-${filename.split(" ").join("_")}`,
        file,
        {
          customPrefix: { public: "blog/" },
          level: "public",
          contentType: file.type,
          ACL: "public-read"
        }
      );

      const imageUrl = await Storage.get(key, {
        customPrefix: { public: "blog/" },
        level: "public"
      });
      onImageUpload(imageUrl.split("?")[0]);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  const pictureClass = value ? "md-avatar-available" : "";
  return (
    <div className="md-profile-avatar">
      <h3 className="d-none d-lg-block mb-2">Profile Pic</h3>
      <div
        className={`md-profile-avatar-control d-flex justify-content-center align-items-center ${pictureClass}`}
        style={{backgroundImage: `url(${value})`}}
      >
        {!value && <i className="hd-icon-user" />}
      </div>
      {isLoading && <Spinner animation="border" size="sm" />}
      <div className="custom-file d-flex mt-3">
        <input
          hidden
          type="file"
          className="custom-file-input"
          id="image"
          accept="image/*"
          onChange={onChange}
          onClick={event => {
            event.target.value = null;
          }}
        />
        <label
          htmlFor="image"
          className="hd-btn hd-btn-dark hd-btn-small d-flex justify-content-center align-items-center font-weight-normal"
        >
          Change
        </label>
      </div>
      <button className="hd-btn hd-btn-danger hd-btn-small font-weight-normal mt-2">
        Delete
      </button>
    </div>
  );
};

export default EditProfilePic;
