import React from "react";
import BlogAvatar from "../BlogAvatar";
import "./blogDetails.scss";
import ShareArticle from "../ShareArticle";

const BlogDetails = ({ blogDetails }) => {
  return (
    <section className="md-fluid-section md-page-section md-blog-detail">
      <div
        className="md-blog-detail-banner d-flex align-items-end mb-4"
        style={{ backgroundImage: `url(${blogDetails.featureImage})` }}
      >
        <div className="md-blog-detail-banner-title d-flex align-items-center justify-content-center w-100">
          <h1 className="m-0">{blogDetails.title}</h1>
        </div>
      </div>
      <div className="container px-md-0">
        <div className="d-flex flex-column flex-md-row justify-content-between mb-4">
          <BlogAvatar
            authorImage={blogDetails.authorImage}
            authorName={blogDetails.authorName}
            timestamp={blogDetails.updatedAt}
            description={blogDetails.description}
          />
          <div className="md-share d-flex align-items-center">
            <ShareArticle />
          </div>
        </div>
        <div
          className="md-detail-desc pt-4"
          dangerouslySetInnerHTML={{
            __html: blogDetails.description
          }}
        />
      </div>
    </section>
  );
};

export default BlogDetails;
