import React from "react";
import { connect } from "react-redux";
import { Query } from "react-apollo";
import Blog from "../components/Blog";
import { getPosts } from "../queries/posts";

const MyBlogContainer = ({ userId }) => {
  return (
    <Query
      query={getPosts}
      fetchPolicy="cache-and-network"
      variables={{ author: userId }}
    >
      {({ loading, error, data, refetch }) => {
        if (loading || error) {
          return null;
        }

        return <Blog blogList={data.getPosts} refetchBlogList={refetch} />;
      }}
    </Query>
  );
};

function mapStateToProps({ auth }) {
  return {
    userId: auth.signedInUser.username
  };
}

export default connect(mapStateToProps)(MyBlogContainer);
