import React from "react";
import { connect } from "react-redux";
import Header from "../components/Header";
import HeaderModal from "./HeaderModal";

const HeaderContainer = props => {
  const { activeUrlHash, currentPath, isUserSignedIn } = props;
  let activePath = currentPath;
  let isBlackBackground = false;
  if (currentPath === "/") {
    activePath = `/#${activeUrlHash}`;
    isBlackBackground = activeUrlHash === "products";
  }
  return [
    <Header
      key="header"
      activePath={activePath}
      isBlackBackground={isBlackBackground}
      isUserSignedIn={isUserSignedIn}
    />,
    <HeaderModal key="modal" />
  ];
};

function mapStateToProps({ app, router, auth }) {
  return {
    activeUrlHash: app.activeUrlHash,
    currentPath: router.location.pathname,
    isUserSignedIn: auth.signedInUser.isUserSignedIn
  };
}

export default connect(mapStateToProps)(HeaderContainer);
