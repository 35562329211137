import React from "react";
import { connect } from "react-redux";
import SignupError from "../components/SignupError";

const SignupErrorContainer = ({ signupData }) => {
  if (!signupData.code) {
    return null;
  }
  const signupError = signupData;

  return <SignupError signupError={signupError} />;
};

function mapStateToProps({ auth }) {
  return {
    signupData: auth.signup
  };
}

export default connect(mapStateToProps)(SignupErrorContainer);
