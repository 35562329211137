import React from "react";

const SocialAuthButtons = () => (
  <div className="hd-social-auth">
    <label className="mb-2">or with social media</label>
    <div className="d-flex">
      <button
        className="d-flex justify-content-center align-items-center hd-btn hb-facebook-btn hd-btn-large"
        type="button"
      >
        <i className="hd-icon-facebook" />Facebook
      </button>
      <button
        className="d-flex justify-content-center align-items-center hd-btn hb-twitter-btn hd-btn-large"
        type="button"
      >
        <i className="hd-icon-twitter" />Twitter
      </button>
      <button
        className="d-flex justify-content-center align-items-center hd-btn hb-google-btn hd-btn-large"
        type="button"
      >
        <i className="hd-icon-google" />Google Plus
      </button>
    </div>
  </div>
);

export default SocialAuthButtons;
